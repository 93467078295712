<template>
  <a-card :bordered="false">
    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"
            ><a-icon type="delete" />删除</a-menu-item
          >
        </a-menu>
        <a-button style="margin-left: 8px">
          批量操作 <a-icon type="down"
        /></a-button>
      </a-dropdown>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :expandedRowKeys="expandedRowKeys"
        @change="handleTableChange"
        @expand="handleExpand"
        v-bind="tableProps"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除吗?"
            @confirm="() => handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="handleAddSub(record)">添加下级</a>
        </span>
      </a-table>
    </div>

    <sysCategoryDict-modal ref="modalForm" @ok="modalFormOk"></sysCategoryDict-modal>
  </a-card>
</template>

<script>
import { getAction, deleteAction } from '@/api/manage';
import SysCategoryDictModal from './modules/SysCategoryDictModal';

export default {
  name: 'SysCategoryCarList',
  components: {
    SysCategoryDictModal,
  },
  data() {
    return {
      description: '车辆分类管理页面',
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {},
      /* 数据源 */
      dataSource: [],
      /* 分页参数 */
      ipagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} 共${total}条`;
        },
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      /* 排序参数 */
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      /* 筛选参数 */
      filters: {},
      /* table加载状态 */
      loading: false,
      /* table选中keys */
      selectedRowKeys: [],
      /* table选中records */
      selectionRows: [],
      /* 查询折叠 */
      toggleSearchStatus: false,
      /* 高级查询条件生效状态 */
      superQueryFlag: false,
      /* 高级查询条件 */
      superQueryParams: '',
      /** 高级查询拼接方式 */
      superQueryMatchType: 'and',
      // 表头
      columns: [
        {
          title: '分类名称',
          align: 'left',
          dataIndex: 'name',
        },
        // {
        //   title: '分类编码',
        //   align: 'left',
        //   dataIndex: 'code',
        // },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      url: {
        list: '/api/sys/dict/category/rootList',
        childList: '/api/sys/dict/category/childList',
        delete: '/api/sys/dict/category/delete',
        deleteBatch: '/api/sys/dict/category/deleteBatch',
      },
      expandedRowKeys: [],
      hasChildrenField: 'hasChild',
      pidField: 'pid',
      dictOptions: {},
      subExpandedKeys: [],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    tableProps() {
      const _this = this;
      return {
        // 列表项是否可选择
        rowSelection: {
          selectedRowKeys: _this.selectedRowKeys,
          onChange: (selectedRowKeys) => (_this.selectedRowKeys = selectedRowKeys),
        },
      };
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // 分页、排序、筛选变化时触发
      // TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = sorter.order == 'ascend' ? 'asc' : 'desc';
      }
      this.ipagination = pagination;
      this.loadData();
    },
    handleAdd() {
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = '新增';
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit(record) {
      this.$refs.modalForm.edit(record,true);
      this.$refs.modalForm.title = '编辑';
      this.$refs.modalForm.disableSubmit = false;
    },
    batchDel() {
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!');
        return;
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！');
      } else {
        let ids = '';
        for (let a = 0; a < this.selectedRowKeys.length; a++) {
          ids += `${this.selectedRowKeys[a]},`;
        }
        const that = this;
        this.$confirm({
          title: '确认删除',
          content: '是否删除选中数据?',
          onOk() {
            that.loading = true;
            deleteAction(`${that.url.deleteBatch}?ids=${ids}`)
              .then((res) => {
                if (res != undefined) {
                  // that.$message.success(res.message);
                  that.loadData();
                  that.onClearSelected();
                } else {
                  that.$message.warning(res.message);
                }
              })
              .finally(() => {
                that.loading = false;
              });
          },
        });
      }
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    loadData(arg) {
      if (arg == 1) {
        this.ipagination.current = 1;
      }
      this.loading = true;
      this.expandedRowKeys = [];
      const params = this.getQueryParams();
      getAction(this.url.list, params).then((res) => {
        if (res.records !== undefined) {
          if (Number(res.total) > 0) {
            this.ipagination.total = Number(res.total);
            this.dataSource = this.getDataByResult(res.records);
          } else {
            this.ipagination.total = 0;
            this.dataSource = [];
          }
        } else {
          this.$message.warning(res.message);
        }
        this.loading = false;
      });
    },
    getDataByResult(result) {
      return result.map((item) => {
        // 判断是否标记了带有子节点
        if (item[this.hasChildrenField] == '1') {
          const loadChild = {
            id: `${item.id}_loadChild`,
            name: 'loading...',
            isLoading: true,
          };
          item.children = [loadChild];
        }
        return item;
      });
    },
    getQueryField() {
      // TODO 字段权限控制
      let str = 'id,';
      this.columns.forEach((value) => {
        str += `,${value.dataIndex}`;
      });
      return str;
    },

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    filterObj(obj) {
      if (!(typeof obj === 'object')) {
        return;
      }
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key)
          && (obj[key] == null || obj[key] == undefined || obj[key] === '')
        ) {
          delete obj[key];
        }
      }
      return obj;
    },
    getQueryParams() {
      console.log('getQueryParams');
      // 获取查询条件
      const sqp = {};
      if (this.superQueryParams) {
        sqp.superQueryParams = encodeURI(this.superQueryParams);
        sqp.superQueryMatchType = this.superQueryMatchType;
      }
      const param = Object.assign(
        sqp,
        this.queryParam,
        this.isorter,
        this.filters
      );
      param.field = this.getQueryField();
      param.pageNo = this.ipagination.current;
      param.pageSize = this.ipagination.pageSize;
      return this.filterObj(param);
    },
    handleExpand(expanded, record) {
      // 判断是否是展开状态
      if (expanded) {
        this.expandedRowKeys.push(record.id);
        if (
          record.children.length > 0
          && record.children[0].isLoading === true
        ) {
          const params = this.getQueryParams(); // 查询条件
          params[this.pidField] = record.id;
          getAction(this.url.childList, params).then((res) => {
            if (res !== undefined) {
              if (res.length > 0) {
                record.children = this.getDataByResult(res);
                this.dataSource = [...this.dataSource];
              } else {
                record.children = '';
                record.hasChildrenField = '0';
              }
            } else {
              this.$message.warning(res.message);
            }
          });
        }
      } else {
        const keyIndex = this.expandedRowKeys.indexOf(record.id);
        if (keyIndex >= 0) {
          this.expandedRowKeys.splice(keyIndex, 1);
        }
      }
    },
    initDictConfig() {},
    modalFormOk(formData, arr) {
      if (!formData.id) {
        this.addOk(formData, arr);
      } else {
        this.editOk(formData, this.dataSource);
        this.dataSource = [...this.dataSource];
      }
    },
    editOk(formData, arr) {
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == formData.id) {
            arr[i] = formData;
            break;
          } else {
            this.editOk(formData, arr[i].children);
          }
        }
      }
    },
    async addOk(formData, arr) {
      if (!formData[this.pidField]) {
        this.loadData();
      } else {
        this.expandedRowKeys = [];
        console.log('22222', arr);
        for (const i of arr) {
          await this.expandTreeNode(i);
        }
      }
    },
    expandTreeNode(nodeId) {
      return new Promise((resolve, reject) => {
        this.getFormDataById(nodeId, this.dataSource);
        const row = this.parentFormData;
        this.expandedRowKeys.push(nodeId);
        const params = this.getQueryParams(); // 查询条件
        params[this.pidField] = nodeId;
        getAction(this.url.childList, params).then((res) => {
          console.log('11111', res);
          if (res !== undefined) {
            if (res.length > 0) {
              row.children = this.getDataByResult(res);
              this.dataSource = [...this.dataSource];
              resolve();
            } else {
              row.children = '';
              row.hasChildrenField = '0';
              reject();
            }
          } else {
            reject();
          }
        });
      });
    },
    getFormDataById(id, arr) {
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == id) {
            this.parentFormData = arr[i];
          } else {
            this.getFormDataById(id, arr[i].children);
          }
        }
      }
    },
    handleAddSub(record) {
      this.subExpandedKeys = [];
      this.getExpandKeysByPid(record.id, this.dataSource, this.dataSource);
      this.$refs.modalForm.subExpandedKeys = this.subExpandedKeys;
      this.$refs.modalForm.title = '添加子分类';
      this.$refs.modalForm.edit({ pid: record.id });
      this.$refs.modalForm.disableSubmit = false;
    },
    handleDelete(record) {
      const that = this;
      const iurl = `${that.url.delete}?id=${record.id}`;
      deleteAction(iurl).then((res) => {
        if (res !== undefined) {
          if (record.pid && record.pid !== '0') {
            const formData = { pid: record.pid };
            that.subExpandedKeys = [];
            that.getExpandKeysByPid(
              record.pid,
              this.dataSource,
              this.dataSource
            );
            that.addOk(formData, this.subExpandedKeys.reverse());
          } else {
            that.loadData();
          }
        } else {
          that.$message.warning('失败');
        }
      });
    },
    // 添加子分类时获取所有父级id
    getExpandKeysByPid(pid, arr, all) {
      if (pid && arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id == pid) {
            this.subExpandedKeys.push(arr[i].id);
            this.getExpandKeysByPid(arr[i].pid, all, all);
          } else {
            this.getExpandKeysByPid(pid, arr[i].children, all);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
</style>
